<template>
  <div class="order_box">
    <div class="filter_Feature_Style">
      <div class="filter_Feature_items">
        <div class="filter_Feature_content">
          <div class="screen_title">始发站：</div>
          <el-cascader v-model="startAddress" :options="options" clearable @change="(val)=> handleChange(val,'1')" :props="{value: 'code',label:'name', children:'children'}" size="mini" class="screenInput"></el-cascader>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">目的站：</div>
          <el-cascader v-model="endAddress" :options="options" clearable @change="(val)=> handleChange(val,'2')" :props="{value: 'code',label:'name', children:'children'}" size="mini" class="screenInput"></el-cascader>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">订单号：</div>
          <el-input class="screenInput" v-model.trim="params.surfaceNumber" size="mini" clearable></el-input>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">发货人：</div>
          <el-input class="screenInput" v-model.trim="params.consignorName" size="mini" clearable></el-input>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">收货人：</div>
          <el-input class="screenInput" v-model.trim="params.consigneeName" size="mini" clearable></el-input>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">货物类型：</div>
          <el-select class="screenInput" v-model="params.goodsType" placeholder="请选择" size="mini" clearable>
            <el-option v-for="item in goodsList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">时效类型：</div>
          <el-select class="screenInput" v-model="params.speedType" placeholder="请选择" size="mini" clearable>
            <el-option v-for="item in agingList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">建单日期：</div>
          <el-date-picker class="screenInput" v-model="time" value-format="yyyy-MM-dd" @change="dateStatus" type="date" size="mini" placeholder="选择建单日期"></el-date-picker>
        </div>
      </div>
      <div class="query_btn">
        <el-button type="reset" size="mini" @click="reset">重 置</el-button>
        <el-button type="button" size="mini" @click="query">查 询</el-button>
      </div>
    </div>

    <div class="table_box">
      <el-table :data="tableData" border style="width: 100%" :header-cell-style="{ 
      background:'#ebeef5',hight:'50px',fontSize:'12px'}" v-loading="tableLoading" element-loading-text="数据正在加载中...">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column label="订单号" width="230" align="center">
          <template slot-scope="scope">
            <span class="details_style" @click="viewDetails(scope.row)">{{scope.row.surfaceNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="originalNumber" label="原始单号" width="180" align="center"></el-table-column>
        <el-table-column prop="status2Text" label="状态" align="center"></el-table-column>
        <el-table-column prop="compName" label="承运商" width="200" align="center"></el-table-column>
        <el-table-column label="发货人" width="120" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.shipmentsAddress.userName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="发货联系电话" width="150" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.shipmentsAddress.mobile}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="发货省-市-区" width="180" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.shipmentsAddress.province}}{{scope.row.shipmentsAddress.city}}{{scope.row.shipmentsAddress.area}}</span>
          </template>
        </el-table-column>
        <el-table-column label="发货详细地址" width="180" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.shipmentsAddress.address}}</span>
          </template>
        </el-table-column>
        <el-table-column label="收货人" align="center" width="120">
          <template slot-scope="scope">
            <span>{{scope.row.arriveAddress.userName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="收货联系电话" align="center" width="150">
          <template slot-scope="scope">
            <span>{{scope.row.arriveAddress.mobile}}</span>
          </template>
        </el-table-column>
        <el-table-column label="收货省-市-区" align="center" width="180">
          <template slot-scope="scope">
            <span>{{scope.row.arriveAddress.province}}{{scope.row.arriveAddress.city}}{{scope.row.arriveAddress.area}}</span>
          </template>
        </el-table-column>
        <el-table-column label="收货详细地址" align="center" width="180">
          <template slot-scope="scope">
            <span>{{scope.row.arriveAddress.address}}</span>
          </template>
        </el-table-column>
        <el-table-column label="时效类型" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.pathInfoObj.speedType == 1">标准</span>
            <span v-if="scope.row.pathInfoObj.speedType == 2">普快</span>
            <span v-if="scope.row.pathInfoObj.speedType == 3">特快</span>
          </template>
        </el-table-column>
        <el-table-column label="货物类型" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.pathInfoObj.goodsType">
              <span v-if="scope.row.pathInfoObj.goodsType == 1">普货</span>
              <span v-if="scope.row.pathInfoObj.goodsType == 2">冷链</span>
              <span v-if="scope.row.pathInfoObj.goodsType == 3">医药</span>
              <span v-if="scope.row.pathInfoObj.goodsType == 4">危化</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="货物名称" align="center" width="150">
          <template slot-scope="scope">
            <span>{{scope.row.cargo[0].gdName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="重量" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.cargo[0].gdWeight}}</span>
          </template>
        </el-table-column>
        <el-table-column label="件数" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.cargo[0].gdNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdDt" label="建单时间" align="center" width="150"></el-table-column>
        <el-table-column prop="userName" label="建单人" align="center" width="120"></el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" small background :current-page="params.pageNum" :page-size="params.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { orderList, addressList, agingType, productType } from "@/api/basics";
import utils from "@/plugin/utils/utils";
export default {
  name:"/order/order",
  data() {
    return {
      tableData: [],
      startAddress: [], //始发站：
      endAddress: [], //目的站
      options: [],
      params: {
        pageSize: 10,
        pageNum: 1,
      },
      total: 0,
      agingList: [], //时效类型
      goodsList: [], //产品类型
      time: "",
      tableLoading: false,
    };
  },
  mounted() {
    this.getList();
    this.getAddress(); //省市区
    this.getAging();
    this.getProduct();
  },
  methods: {
    query() {
      this.params.pageNum = 1;
      this.getList();
    },
    async getList() {
      //起始地
      console.log(this.startAddress, "startAddress");
      this.tableLoading = true;

      //起始地
        if (this.startAddress.length > 0) {
          this.params.startProvinceCode = this.startAddress[0];
          this.params.startCityCode = this.startAddress[1];
          this.params.startAreaCode = this.startAddress[2]
            ? this.startAddress[2]
            : "";
        }
        //目的地
        if (this.endAddress.length > 0) {
          this.params.endProvinceCode = this.endAddress[0];
          this.params.endCityCode = this.endAddress[1];
          this.params.endAreaCode = this.endAddress[2]
            ? this.endAddress[2]
            : "";
        }
      let data = utils.removeNull(this.params); //去除空字符串
      console.log(data, "入参");
      try {
        let res = await orderList(data);
        console.log(res, "res");
        if (res.retCode == "0000000") {
          this.tableLoading = false;
          this.tableData = res.rspBody.items;
          this.total = res.rspBody.total;
        } else {
          this.tableLoading = false;
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (error) {
        console.log(error);
        this.tableLoading = false;
        this.$message({
          type: "error",
          message: error.retDesc,
        });
      }
    },

    reset() {
      this.params = utils.clearQuery(this.params);
      this.startAddress = [];
      this.endAddress = [];
      this.params.pageSize = 10;
      this.params.pageNum = 1;
      this.time = [];
      this.getList();
    },

    //省市区处理
    async getAddress() {
      try {
        let res = await addressList({});
        var list = res.rspBody;
        if (list.length > 0) {
          this.options = utils.addressData(list);
        } else {
          this.options = [];
        }
      } catch (err) {
        console.log(err);
      }
    },

    //时效类型
    async getAging() {
      try {
        let res = await agingType();
        console.log(res);
        this.agingList = res.rspBody;
      } catch (err) {
        console.log(err);
      }
    },

    //产品类型
    async getProduct() {
      try {
        let res = await productType();
        console.log(res);
        this.goodsList = res.rspBody;
      } catch (err) {
        console.log(err);
      }
    },

    dateStatus(val) {
      console.log(val);
      if (val == null) {
        this.time = "";
        this.params.createAtStart = "";
        this.params.createAtEnd = "";
      } else {
        this.time = val;
        this.params.createAtStart = val + " 00:00:00";
        this.params.createAtEnd = val + " 23:59:59";
      }
    },

    handleChange(value,index) {
      console.log(value, "省市区");
      if(index == 1) {
        this.params.startAreaCode  = "";
        this.params.startCityCode  = "";
        this.params.startProvinceCode  = "";
      }else {
        this.params.endAreaCode  = "";
        this.params.endCityCode  = "";
        this.params.endProvinceCode  = "";
      }

    },

    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.getList();
    },

    viewDetails(row) {
      console.log(row,'row');
      var list = JSON.stringify(row);
      this.$router.push({
        path: "/order/orderDetails",
        query: {
          details: list,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order_box {
  height: 100%;
  width: 100%;

  .details_style {
    color: #086ffd;
    cursor: pointer;
  }
}
</style>